$jost: "Jost", sans-serif;
$dmSans: "DM Sans", sans-serif;
$josefinSans: "Josefin Sans", sans-serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-extra-light: #fab7d3;
$primary-light: #9b3961;
$primary-main: #8C3156;
$primary-dark: #782748;
$primary-extra-dark: #661f3c;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;

$color-white: #FFFFFF;

$primary-button-background: #8C3156;
$primary-button-background-hover: #782748;
$primary-button-shadow: rgba(28, 49, 68, 0.24) 0 8px 16px 0;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-text: #D9D9D9;
$color-grey-icon: #131A29;
$color-grey-border: #EDEDED;
$color-orange: #E9471D;
$color-orange-darker: #ee4216;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #E8E9EB;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #FFFFFF;
$black-color: #000000;

$red-color: #E13119;
$red-color-darker: #e04321;
$red-color-brighter: #EFC5BD;

$warning-color: #F19A3E;
$warning-color-brighter: #F6C48D;
$warning-color-darker: #98540B;

$sidebar-color: #313638;
$navigation-color: #313638;

